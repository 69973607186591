<template>
  <div class="app">
    <AppHeader fixed >
      <SidebarToggler class="d-lg-none" display="md" mobile  />
      <b-link class="navbar-brand"  to="#">
        <img class="navbar-brand-full" src="img/brand/youngster_logo.png" width="110"  alt="ar geniessen">
        <img class="navbar-brand-minimized" src="img/brand/youngster_logo.png" width="40" alt="ar geniessen">
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" style="display:none" />
      <b-navbar-nav class="d-md-down-none ml-auto">
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdown/>
        </b-nav-item>
      </b-navbar-nav>
      <!--AsideToggler class="d-none d-lg-block" /-->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav v-if="authObj && authObj.rolename == 'Administrator'" :navItems="adminNavItems"></SidebarNav>
        <SidebarNav v-else :navItems="navItems"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <!--Breadcrumb :list="list"/-->
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <!--<AppAside fixed>-->
        <!--&lt;!&ndash;aside&ndash;&gt;-->
        <!--<DefaultAside/>-->
      <!--</AppAside>-->
    </div>
    <TheFooter>
      <!--footer-->
      <div class="ml-auto">
        <span class="mr-1">Version 1.0</span>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import nav from '@/_nav'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter, /*Breadcrumb*/ } from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdown from './DefaultHeaderDropdown'
import { mapGetters } from 'vuex'

export default {
  name: 'DefaultContainer',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
//    Breadcrumb,
//    DefaultAside,
    DefaultHeaderDropdown,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data () {
    return {
        navItems: nav.items,
        adminNavItems: nav.admin_items
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    },
    ...mapGetters({
        authObj: 'getAuthObj',
    })
  }
}
</script>
