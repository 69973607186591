<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <i class="cui-user icons font-2xl d-block"></i>
    </template>
    <template v-if="authObj" slot="dropdown">
      <b-dropdown-header tag="div" class="text-center"><strong>{{authObj.firstname}}&nbsp;{{authObj.lastname}}</strong></b-dropdown-header>
      <b-dropdown-item v-on:click="changePw()"><i class="fa fa-shield" />Passwort ändern</b-dropdown-item>
      <b-dropdown-item v-on:click="logout()"><i class="fa fa-lock" /> Abmelden</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>

import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DefaultHeaderDropdown',
  computed: {
      ...mapGetters({
          authObj: 'getAuthObj',
      })
  },
  components: {
    AppHeaderDropdown
  },
  methods: {
      logout() {
          this.$store.dispatch('doLogout', this.authObj.username)
      },
      changePw() {
          this.$router.replace(this.$route.query.redirect || '/user/editUser/'+this.authObj.username+'?changePw=true')
      }
  }
}
</script>

