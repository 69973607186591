export default {
    admin_items: [
      {
        name: 'Unternehmen',
        url: '/company',
        icon: 'icon-grid'
      },
      {
        name: 'Medienverwaltung',
        url: '/media',
        icon: 'icon-layers'
      },
      {
        name: 'Eigenschaften',
        url: '/properties',
        icon: 'icon-star',
        component: {
            render (c) { return c('router-view') }
        },
        children: [
            // {
            //     name: 'Categories',
            //     url: '/property/categories',
            //     icon: 'icon-star'
            // },
            // {
            //     name: 'Tags',
            //     url: '/property/properties/tags',
            //     icon: 'icon-star'
            // },
            {
                name: 'Schulabschluss',
                url: '/property/properties/graduation',
                icon: 'icon-star'
            },
            {
                name: 'Fachbereich',
                url: '/property/properties/department',
                icon: 'icon-star'
            },
            {
                name: 'Branch',
                url: '/property/properties/branch',
                icon: 'icon-star'
            },
            {
                name: 'Zeitraum',
                url: '/property/properties/begin-period',
                icon: 'icon-star'
            },
        ]
      },
      {
          name: "Blog",
          url: '/blog',
          icon: 'icon-bubble',
          component: {
              render (c) { return c('router-view') }
          },
          children: [
              {
                  name: 'Personen',
                  url: '/blog/persons',
                  icon: 'icon-user'
              },
              {
                  name: 'Blogkategorien',
                  url: '/blog/blogCategories',
                  icon: 'icon-star'
              },
              {
                  name: 'Blogbeiträge',
                  url: '/blog/blogEntries',
                  icon: 'icon-bubble'
              },
          ]
      },
      {
          name: 'Benutzerverwaltung',
          url: '/user',
          icon: 'icon-shield'
      },
      // {
    ],
    items: [
        {
            name: 'Medienverwaltung',
            url: '/media',
            icon: 'icon-layers'
        },
    ]
}
